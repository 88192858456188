import React from "react";
import styled from "styled-components";

import { imgix } from "../../styles/variables";
import ImgixImg from "../helpers/ImgixImg";

const BildElem = styled.div`
  width: 100%;
  img {
    width: 100%;
  }
`;

const BildElemLink = styled.a`
  display: block;
  width: 100%;
  img {
    width: 100%;
  }
`;

const Image = ({ image, lightbox, dataLightbox }) => (
  <>
    {lightbox ? (
      <BildElemLink
        href={image + "?auto=format,compress&q=20&w=1600"}
        data-lightbox={dataLightbox}
      >
        <ImgixImg src={image} imgixParams={imgix.halfImage} />
      </BildElemLink>
    ) : (
      <BildElem>
        <ImgixImg src={image} imgixParams={imgix.halfImage} />
      </BildElem>
    )}
  </>
);

export default Image;
