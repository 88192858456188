import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const GridElem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  grid-column-gap: 12px;

  > a {
    display: block;
    width: 100%;
    height: calc(100vw / 9);

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    grid-column-gap: 20px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    grid-column-gap: 30px;
  }
`;

const BilderGrid = ({ children }) => <GridElem>{children}</GridElem>;

export default BilderGrid;
