import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const GridDiv = styled.article`
  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 60px;
    margin-bottom: 60px;
  }
`;

const AkkordeonHalfGrid = ({ children }) => <GridDiv>{children}</GridDiv>;

export default AkkordeonHalfGrid;
