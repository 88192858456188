import { graphql } from "gatsby";
import React from "react";
import AbschlussElemente from "../components/AbschlussElemente";
import Akkordeon from "../components/Akkordeon/Akkordeon";
import AkkordeonContent from "../components/Akkordeon/AkkordeonContent";
import VerfuegbarkeitsGrid from "../components/Akkordeon/VerfuegbarkeitsGrid";
import BackgroundWrapper from "../components/BackgroundWrapper";
import FWThreeImages from "../components/FWThreeImages";
import { getIcon } from "../components/helpers/getIcon";
import IconGrid from "../components/IconGrid/IconGrid";
import IconItem from "../components/IconGrid/IconItem";
import Landing from "../components/Landing";
import PostLandingText from "../components/Text/PostLandingText";
import SectionHeader from "../components/Text/SectionHeader";
import DefaultLayout from "../layouts/default";

import { imgix } from "../styles/variables";
import ImgixImg from "../components/helpers/ImgixImg";

interface AkkordeonSeiteProps {
  data: {
    cms: {
      entry: {
        title: string;
        id: number;
        siteId: number;
        metabeschreibung: string;
        landingBild: [
          {
            url: string;
          }
        ];
        landingTitel: string;
        postLandingText: string;
        inhaltsseitenGrid: [
          {
            titel: string;
            text: string;
            bild: [
              {
                url: string;
              }
            ];
            zwischenelementDreiBilder: [
              {
                url: string;
              }
            ];
            inhaltTitel: string;
            akkordeon: [
              {
                titel: string;
                zentrierterInhaltstext: string;
                inhaltsbildLinks: [
                  {
                    url: string;
                  }
                ];
                inhaltsbildRechts: [
                  {
                    url: string;
                  }
                ];
                inhaltstextLinks: string;
                inhaltstextRechts: string;
                buttonText: string;
                buttonLinkUrl: string;
                buttonLinkDatei: [
                  {
                    url: string;
                  }
                ];
                buttonLinkTelefon: string;
                buttonLinkEmail: string;
                buttonIcon: string;
              }
            ];
            verfuegbarkeitsAkkordeon: [
              {
                zimmertyp: string;
                flaeche: string;
                verfuegbarkeit: string;
              }
            ];
            abschlussElemente: [
              {
                bild: [
                  {
                    url: string;
                  }
                ];
                zitat: string;
                autor: string;
                buttonText: string;
                buttonLinkUrl: string;
                buttonLinkDatei: [
                  {
                    url: string;
                  }
                ];
                buttonLinkTelefon: string;
                buttonLinkEmail: string;
                buttonIcon: string;
                inhaltstext: string;
              }
            ];
          }
        ];
      };
    };
  };
}

export const AkkordeonSeiteQuery: React.FC<AkkordeonSeiteProps> = graphql`
  query ($id: CMS_QueryArgument, $siteId: CMS_QueryArgument) {
    cms {
      entry(id: [$id], siteId: [$siteId]) {
        title
        id
        siteId

        ... on CMS_main_akkordeonSeite_Entry {
          metaBeschreibung
          landingBild {
            url
          }
          landingTitel
          postLandingText
          inhaltsseitenGrid {
            ... on CMS_inhaltsseitenGrid_reihe_BlockType {
              titel
              text
              bild {
                url
              }
            }
          }
          zwischenelementDreiBilder {
            url
          }
          inhaltTitel
          akkordeon {
            ... on CMS_akkordeon_akkordeonItem_BlockType {
              titel
              zentrierterInhaltstext
              inhaltstextVolleBreite
              inhaltsbildLinks {
                url
              }
              inhaltsbildRechts {
                url
              }
              inhaltstextLinks
              inhaltstextRechts
              buttonText
              buttonLinkUrl
              buttonLinkDatei {
                url
              }
              dreiBilderGrid {
                url
              }
              buttonLinkTelefon
              buttonLinkEmail
              buttonIcon
            }
          }
          verfuegbarkeitsAkkordeon {
            ... on CMS_verfuegbarkeitsAkkordeon_zeile_BlockType {
              zimmertyp
              flaeche
              verfuegbarkeit
            }
          }
          abschlussElemente {
            ... on CMS_abschlussElemente_bild_BlockType {
              bild {
                url
              }
            }
            ... on CMS_abschlussElemente_zitat_BlockType {
              zitat
              autor
            }
            ... on CMS_abschlussElemente_button_BlockType {
              buttonText
              buttonLinkUrl
              buttonLinkDatei {
                url
              }
              buttonLinkTelefon
              buttonLinkEmail
              buttonIcon
            }
            ... on CMS_abschlussElemente_zentrierterInhaltstext_BlockType {
              inhaltstext
            }
            ... on CMS_abschlussElemente_zentrierterInhaltstextBgColor_BlockType {
              titel
              text
            }
          }
        }
      }
    }
  }
`;

const AkkordeonSeite = ({ data }) => {
  return (
    <DefaultLayout
      siteId={data.cms.entry.siteId}
      description={data.cms.entry.metaBeschreibung}
      title={data.cms.entry.title}
    >
      {data.cms.entry.landingBild && data.cms.entry.landingBild.length > 0 ? (
        <Landing
          title={data.cms.entry.landingTitel}
          image={data.cms.entry.landingBild[0].url}
        />
      ) : (
        ""
      )}

      {data.cms.entry.postLandingText ? (
        <PostLandingText text={data.cms.entry.postLandingText} />
      ) : (
        ""
      )}

      {data.cms.entry.inhaltsseitenGrid &&
      data.cms.entry.inhaltsseitenGrid.length > 0 ? (
        <BackgroundWrapper>
          <IconGrid>
            {data.cms.entry.inhaltsseitenGrid.map((item, index) => (
              <React.Fragment key={index}>
                <IconItem
                  icon={getIcon(data.cms.entry.siteId)}
                  title={item.titel}
                  text={item.text}
                  key={index + "key"}
                />
                <div className="image" key={index + "key1"}>
                  <ImgixImg
                    src={item.bild[0].url}
                    imgixParams={imgix.halfImage}
                  />
                </div>
              </React.Fragment>
            ))}
          </IconGrid>
        </BackgroundWrapper>
      ) : (
        ""
      )}

      {data.cms.entry.inhaltTitel ? (
        <SectionHeader title={data.cms.entry.inhaltTitel} />
      ) : (
        ""
      )}

      {data.cms.entry.zwischenelementDreiBilder &&
      data.cms.entry.zwischenelementDreiBilder.length > 0 ? (
        <FWThreeImages images={data.cms.entry.zwischenelementDreiBilder} />
      ) : (
        ""
      )}
      {data.cms.entry.akkordeon.length > 0
        ? data.cms.entry.akkordeon.map((item, index) => (
            <Akkordeon
              title={item.titel}
              final={
                data.cms.entry.akkordeon.length - 1 === index ? true : false
              }
              key={index}
            >
              <AkkordeonContent
                content={item}
                siteId={data.cms.entry.siteId}
                akkIndex={index}
              />
            </Akkordeon>
          ))
        : ""}
      {data.cms.entry.verfuegbarkeitsAkkordeon.length > 0 ? (
        <Akkordeon title="<h2>Verfügbarkeit</h2>">
          <VerfuegbarkeitsGrid
            content={data.cms.entry.verfuegbarkeitsAkkordeon}
          />
        </Akkordeon>
      ) : (
        ""
      )}
      {data.cms.entry.abschlussElemente.length > 0 ? (
        <AbschlussElemente
          content={data.cms.entry.abschlussElemente}
          siteId={data.cms.entry.siteId}
        />
      ) : (
        ""
      )}
    </DefaultLayout>
  );
};

export default AkkordeonSeite;
