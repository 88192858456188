import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const GridDiv = styled.div`
  p {
    font: 16px / 23px "FS Regular";
    margin-bottom: 20px;

    &:nth-of-type(1),
    &:nth-of-type(2) {
      font-family: "FS Medium";
      display: inline;
    }

    &:nth-of-type(1) {
      &:after {
        content: " / ";
      }
    }
    &:nth-of-type(3) {
      font-family: "FS Regular Italic";
    }
  }

  @media screen AND (min-width: ${breakpoints.tablet}px) {
    margin-bottom: 60px;
    > div {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 0.7fr 1.3fr;
      margin-bottom: 20px;

      p {
        font: 21px / 30px "FS Regular";
        &:nth-of-type(1) {
          &:after {
            content: "";
          }
        }
        &:nth-of-type(1),
        &:nth-of-type(2),
        &:nth-of-type(3) {
          font-family: "FS Regular";
        }
      }
    }
  }
`;

interface VerfuegbarkeitsGridProps {
  content: [
    {
      zimmertyp: string;
      flaeche: string;
      verfuegbarkeit: string;
    }
  ];
}

const VerfuegbarkeitsGrid: React.FC<VerfuegbarkeitsGridProps> = ({
  content,
}) => (
  <GridDiv>
    {content.map((item, index) => (
      <div key={index}>
        <p>{item.zimmertyp}</p>
        <p>{item.flaeche}</p>
        <p>{item.verfuegbarkeit}</p>
      </div>
    ))}
  </GridDiv>
);

export default VerfuegbarkeitsGrid;
