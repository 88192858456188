import React from "react";
import Button from "../Button";
import { getColor } from "../helpers/getColor";
import Fliesstext from "../Text/Fliesstext";
import AkkordeonHalfGrid from "./AkkordeonHalfGrid";
import BilderGrid from "./BilderGrid";
import Image from "./Image";

import { imgix } from "../../styles/variables";
import ImgixImg from "../helpers/ImgixImg";

interface AkkordeonContentProps {
  content: {
    titel: string;
    zentrierterInhaltstext: string;
    inhaltsbildLinks: [
      {
        url: string;
      }
    ];
    inhaltsbildRechts: [
      {
        url: string;
      }
    ];
    inhaltstextLinks: string;
    inhaltstextRechts: string;
    buttonText: string;
    buttonLinkUrl: string;
    buttonLinkDatei: [
      {
        url: string;
      }
    ];
    buttonLinkTelefon: string;
    buttonLinkEmail: string;
    buttonIcon: string;
  };
  siteId: number;
}

const AkkordeonContent: React.FC<AkkordeonContentProps> = ({
  content,
  siteId,
  akkIndex,
}) => (
  <>
    {content.inhaltstextLinks ||
    content.inhaltstextRechts ||
    content.inhaltsbildLinks.length > 0 ||
    content.inhaltsbildRechts.length > 0 ? (
      <AkkordeonHalfGrid>
        <div>
          {content.inhaltsbildLinks.length > 0 ? (
            <Image
              image={content.inhaltsbildLinks[0].url}
              lightbox
              dataLightbox={"gallery" + akkIndex}
            />
          ) : (
            ""
          )}
          {content.inhaltstextLinks ? (
            <Fliesstext text={content.inhaltstextLinks} siteId={siteId} />
          ) : (
            ""
          )}
        </div>
        <div>
          {content.inhaltsbildRechts.length > 0 ? (
            <Image
              image={content.inhaltsbildRechts[0].url}
              lightbox
              dataLightbox={"gallery" + akkIndex}
            />
          ) : (
            ""
          )}
          {content.inhaltstextRechts ? (
            <Fliesstext text={content.inhaltstextRechts} siteId={siteId} />
          ) : (
            ""
          )}
        </div>
      </AkkordeonHalfGrid>
    ) : (
      ""
    )}
    {content.inhaltstextVolleBreite ? (
      <Fliesstext text={content.inhaltstextVolleBreite} siteId={siteId} />
    ) : (
      ""
    )}
    {content.dreiBilderGrid && content.dreiBilderGrid.length > 0 ? (
      <BilderGrid>
        {content.dreiBilderGrid.map((item, index) => (
          <a
            href={item.url + "?auto=format,compress&q=20&w=1600"}
            data-lightbox={"gallery" + akkIndex}
            key={index}
          >
            <ImgixImg src={item.url} imgixParams={imgix.thirdImage} />
          </a>
        ))}
      </BilderGrid>
    ) : (
      ""
    )}
    {content.zentrierterInhaltstext ? (
      <Fliesstext
        text={content.zentrierterInhaltstext}
        centered
        siteId={siteId}
      />
    ) : (
      ""
    )}
    {content.buttonText ? (
      <Button
        text={content.buttonText}
        color={getColor(siteId)}
        icon={content.buttonIcon}
        link={
          content.buttonLinkDatei.length > 0
            ? content.buttonLinkDatei[0].url
            : content.buttonLinkUrl
            ? content.buttonLinkUrl
            : content.buttonLinkTelefon
            ? `tel:${content.buttonLinkTelefon}`
            : content.buttonLinkEmail
            ? `mailto:${content.buttonLinkEmail}`
            : ""
        }
      />
    ) : (
      ""
    )}
  </>
);

export default AkkordeonContent;
